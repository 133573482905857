import React, { useState, useEffect } from "react";
import EnvatoDownloader from "./envatoDownloader";

export default function Login() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const API_ENDPOINT = "https://alphashopy.com/validate_email.php";

  useEffect(() => {
    const storedAuth = localStorage.getItem("isAuthenticated");
    const storedEmail = localStorage.getItem("userEmail");
    if (storedAuth === "true" && storedEmail) {
      setIsAuthenticated(true);
      setEmail(storedEmail);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();

      if (result.isValid) {
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("userEmail", email);
      } else {
        setError("Your email is not authorized to access the site.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setEmail("");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("userEmail");
  };

  return (
    <>
      {!isAuthenticated ? (
        <div className="login-container">
          <div className="login-card">
            <h2 className="login-title">Welcome to AlphaShopy</h2>
            <p className="login-subtitle">Please enter your email to login</p>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="input-group">
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="login-input"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button type="submit" className="login-button">
                Login
              </button>
            </form>
            {error && <div className="error-message">{error}</div>}
          </div>
        </div>
      ) : (
        <div className="authenticated-container">
          <div className="authenticated-header">
            <h2 className="welcome-message">Welcome, {email}!</h2>
            <button onClick={handleLogout} className="logout-button">
              Logout
            </button>
          </div>
          <EnvatoDownloader />
        </div>
      )}

      
      <style jsx>{`
        .login-container,
        .authenticated-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
          font-family: "Arial", sans-serif;
        }
        .login-card {
          background: white;
          padding: 2rem;
          border-radius: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          width: 100%;
          max-width: 400px;
        }
        .login-title {
          color: #333;
          font-size: 1.8rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .login-subtitle {
          color: #666;
          font-size: 1rem;
          text-align: center;
          margin-bottom: 1.5rem;
        }
        .login-form {
          display: flex;
          flex-direction: column;
        }
        .input-group {
          margin-bottom: 1rem;
        }
        .login-input {
          width: 100%;
          padding: 0.75rem;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 1rem;
          transition: border-color 0.3s ease;
        }
        .login-input:focus {
          outline: none;
          border-color: #4299e1;
          box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
        }
        .login-button,
        .logout-button {
          background-color: #4299e1;
          color: white;
          border: none;
          padding: 0.75rem;
          border-radius: 4px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .login-button:hover,
        .logout-button:hover {
          background-color: #3182ce;
        }
        .authenticated-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          max-width: 800px;
          margin-bottom: 2rem;
          padding: 1rem;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        }
        .welcome-message {
          color: white;
          font-size: 1.2rem;
          margin: 0;
        }
        .error-message {
          color: #e53e3e;
          font-size: 0.875rem;
          text-align: center;
          margin-top: 1rem;
        }
      `}</style>
    </>


  );
}