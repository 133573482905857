'use client'

import React, { useState } from 'react'
import { AlertCircle, Download, Loader } from 'lucide-react'

const API_ENDPOINTS = {
  envato: 'https://api.pixhub.in/api/download/envanto',
  freepik: 'https://api.pixhub.in/api/download/image',
  flaticon: 'https://api.pixhub.in/api/download/flaticon'
}

export default function Downloader() {
  const [activeTab, setActiveTab] = useState('envato')
  const [url, setUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [result, setResult] = useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    setError(null)
    setResult(null)

    try {
      const response = await fetch(API_ENDPOINTS[activeTab], {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY3MzJmODVmZmY3YzYzMTBhYWM1NTM2YiIsImlhdCI6MTczMTM5OTA1OX0.vH_HCiOZTRPh3qBLkac0jloOyuNqAGqYnQi5C3aEnC0',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fileUrl: url })
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      setResult(data)
    } catch (err) {
      setError(err.message || 'An error occurred while processing your request')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="downloader1">
      <div className="card">
        <h1 className="card-title">Asset Downloader</h1>
        <p className="card-description">Download assets from Envato, Freepik, and Flaticon</p>
        
        <div className="tabs">
          <button 
            className={`tab ${activeTab === 'envato' ? 'active' : ''}`} 
            onClick={() => setActiveTab('envato')}
          >
            Envato
          </button>
          <button 
            className={`tab ${activeTab === 'freepik' ? 'active' : ''}`} 
            onClick={() => setActiveTab('freepik')}
          >
            Freepik
          </button>
          <button 
            className={`tab ${activeTab === 'flaticon' ? 'active' : ''}`} 
            onClick={() => setActiveTab('flaticon')}
          >
            Flaticon
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className="download-form">
          <input
            type="url"
            placeholder={`Enter ${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} URL`}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
            className="url-input"
          />
          <button type="submit" disabled={isLoading} className="submit-button">
            {isLoading ? (
              <>
                <Loader className="icon spinning" />
                Processing
              </>
            ) : (
              'Download'
            )}
          </button>
        </form>
        
        {error && (
          <div className="alert error">
            <AlertCircle className="icon" />
            <p><strong>Error:</strong> {error}</p>
          </div>
        )}
        
        {result && (
          <div className="alert success">
            <Download className="icon" />
            <p>
              <strong>Success:</strong>{' '}
              <a href={result.downloadLink} target="_blank" rel="noopener noreferrer" className="download-link">
                Click here to download
              </a>
            </p>
          </div>
        )}
      </div>
      
      <style jsx>{`
        .downloader {
          font-family: Arial, sans-serif;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 100vh;
          background-color: #f0f2f5;
        }
        .card {
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 2rem;
          width: 100%;
          max-width: 500px;
        }
        .card-title {
          font-size: 1.5rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .card-description {
          text-align: center;
          color: #666;
          margin-bottom: 1.5rem;
        }
        .tabs {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.5rem;
        }
        .tab {
          flex: 1;
          padding: 0.75rem;
          border: none;
          background-color: #f0f2f5;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        .tab:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        .tab:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        .tab.active {
          background-color: #007bff;
          color: white;
        }
        .download-form {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
        .url-input {
          padding: 0.75rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 1rem;
        }
        .submit-button {
          padding: 0.75rem;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .submit-button:hover {
          background-color: #0056b3;
        }
        .submit-button:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
        .alert {
          margin-top: 1rem;
          padding: 0.75rem;
          border-radius: 4px;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        .error {
          background-color: #ffebee;
          color: #d32f2f;
        }
        .success {
          background-color: #e8f5e9;
          color: #1b5e20;
        }
        .icon {
          width: 1.25rem;
          height: 1.25rem;
        }
        .spinning {
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        .download-link {
          color: #007bff;
          text-decoration: underline;
        }
        .download-link:hover {
          color: #0056b3;
        }
      `}</style>
    </div>
  )
}